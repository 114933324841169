
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';


.fa-2x {
  font-size: 1.5em;
}
i.hovered {position: relative;}
i.hovered::after {
  content: attr(data-message);
  position: absolute;
  width: 100px;
  height: 30px;
  background: #000;
  bottom: -40px;
  left: -90px;
  justify-content: center;
  align-items: center;
  color: #fff;
  border-radius: 10px 0px 10px 10px;
  display: none;
  font-family: Nunito;
  font-size: 13px;
}
i:hover::after {
  display: flex;
}
.app {
  position: relative;
  overflow: hidden;
  top: 19px;
  height: calc(100% - 38px);
  margin: auto;
  padding: 0;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, .06), 0 2px 5px 0 rgba(0, 0, 0, .2);
}

.app-one {
  height: 100%;
  overflow: hidden;
  margin: 0;
  padding: 0;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, .06), 0 2px 5px 0 rgba(0, 0, 0, .2);
  transform: scale(0.9);
  transition: .5s;
}

.side {
  padding: 0;
  margin: 0;
  height: 100%;
}
.side-one {
  padding: 0;
  margin: 0;
  height: 100%;
  width: 100%;
  z-index: 1;
  position: relative;
  display: block;
  top: 0;
  background: #2a2f32;
}

.side-two {
  padding: 0;
  margin: 0;
  height: 100%;
  width: 100%;
  z-index: 2;
  position: relative;
  top: -100%;
  left: -100%;
  -webkit-transition: left 0.3s ease;
  transition: left 0.3s ease;

}

.container {
  max-width: 1340px!important;
}

.heading {
  padding: 10px 16px 10px 15px;
  margin: 0;
  height: 60px;
  width: 100%;
  background-color: #eee;
  z-index: 1000;
  display: flex;
  justify-content: space-between;
}

.heading-avatar {
  padding: 0;
  cursor: pointer;

}

.heading-avatar-icon img {
  border-radius: 50%;
  height: 40px;
  width: 40px;
}

.heading-name {
  padding: 0 !important;
  cursor: pointer;
}

.heading-name-meta {
  font-weight: 700;
  font-size: 100%;
  padding: 5px;
  padding-bottom: 0;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #000;
  display: block;
}
.heading-online {
  display: none;
  padding: 0 5px;
  font-size: 12px;
  color: #93918f;
}
.heading-compose {
  padding: 0;
}

.heading-compose i {
  text-align: center;
  padding: 5px;
  color: #93918f;
  cursor: pointer;
}

.heading-dot {
  padding: 0;
  margin-left: 10px;
}

.heading-dot i {
  text-align: right;
  padding: 5px;
  color: #93918f;
  cursor: pointer;
}

.searchBox {
  padding: 0 !important;
  margin: 0 !important;
  height: 60px;
  width: 100%;
}

.searchBox-inner {
  height: 100%;
  width: 100%;
  padding: 10px !important;
  background-color: #fbfbfb;
}


/*#searchBox-inner input {
  box-shadow: none;
}*/

.searchBox-inner input:focus {
  outline: none;
  border: none;
  box-shadow: none;
}

.sideBar {
  padding: 0 !important;
  margin: 0 !important;
  background-color: #0f0f0f;
  overflow-y: auto;
  height: calc(100% - 120px);
  display: grid;
  align-content: baseline;
  border-left: 3px solid;
}
::-webkit-scrollbar {
    width: 5px;
  }
  
  /* Track */
::-webkit-scrollbar-track {
background: #fff; 
}

.d-grid {
  display: grid;
}

/* Handle */
::-webkit-scrollbar-thumb {
background: #c5c4c4; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
background: #c5c4c4; 
}
.user1-video {
    border-bottom: 3px solid;
}
.sideBar-body {
  position: relative;
  padding: 10px !important;
  border-bottom: 1px solid #f7f7f7;
  height: 72px;
  margin: 0 !important;
  cursor: pointer;
}

.sideBar-body:hover {
  background-color: #f2f2f2;
}

.sideBar-avatar {
  text-align: center;
  padding: 0 !important;
}

.avatar-icon img {
  border-radius: 50%;
  height: 49px;
  width: 49px;
}

.sideBar-main {
  padding: 0 !important;
}

.sideBar-main .row {
  padding: 0 !important;
  margin: 0 !important;
}

.sideBar-name {
  padding: 10px !important;
}

.name-meta {
  font-size: 100%;
  padding: 1% !important;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #000;
}

.sideBar-time {
  padding: 10px !important;
}

.time-meta {
  text-align: right;
  font-size: 12px;
  padding: 1% !important;
  color: rgba(0, 0, 0, .4);
  vertical-align: baseline;
}

/*New Message*/

.newMessage {
  padding: 0 !important;
  margin: 0 !important;
  height: 100%;
  position: relative;
  left: -100%;
}
.newMessage-heading {
  padding: 10px 16px 10px 15px !important;
  margin: 0 !important;
  height: 100px;
  width: 100%;
  background-color: #2a2f32;
  z-index: 1001;
}


.newMessage-main {
  padding: 10px 16px 0 15px !important;
  margin: 0 !important;
  height: 60px;
  margin-top: 30px !important;
  width: 100%;
  z-index: 1001;
  color: #fff;
}

.newMessage-title {
  font-size: 18px;
  font-weight: 700;
  padding: 10px 5px !important;
}
.newMessage-back {
  text-align: center;
  vertical-align: baseline;
  padding: 12px 5px !important;
  display: block;
  cursor: pointer;
}
.newMessage-back i {
  margin: auto !important;
}

.composeBox {
  padding: 0 !important;
  margin: 0 !important;
  height: 60px;
  width: 100%;
}

.composeBox-inner {
  height: 100%;
  width: 100%;
  padding: 10px !important;
  background-color: #fbfbfb;
}

.composeBox-inner input:focus {
  outline: none;
  border: none;
  box-shadow: none;
}

.compose-sideBar {
  padding: 0 !important;
  margin: 0 !important;
  background-color: #fff;
  overflow-y: auto;
  border: 1px solid #f7f7f7;
  height: calc(100% - 160px);
}

/*Conversation*/

.conversation {
  padding: 0 !important;
  margin: 0 !important;
  height: 100%;
  /*width: 100%;*/
  border-left: 3px solid #2a2f32;
  /*overflow-y: auto;*/
  background: #2a2f32;
}

.message {
  padding: 0 !important;
  margin: 0 !important;
  /* background: url("w.jpg") no-repeat fixed center; */
  background-size: cover;
  overflow-y: auto;
  height: calc(100% - 120px);
}
.message-previous {
  margin : 0 !important;
  padding: 0 !important;
  height: auto;
  width: 100%;
}
.previous {
  font-size: 15px;
  text-align: center;
  padding: 10px !important;
  cursor: pointer;
}

.previous a {
  text-decoration: none;
  font-weight: 700;
}

.message-body {
  margin: 0 !important;
  padding: 0 !important;
  width: auto;
  height: auto;
}

.message-main-receiver {
  /*padding: 10px 20px;*/
  max-width: 60%;
}

.message-main-sender {
  padding: 3px 20px !important;
  margin-left: 40% !important;
  max-width: 60%;
}

.message-text {
  margin: 0 !important;
  padding: 5px !important;
  word-wrap:break-word;
  font-weight: 200;
  font-size: 14px;
  padding-bottom: 0 !important;
}

.message-time {
  margin: 0 !important;
  margin-left: 50px !important;
  font-size: 12px;
  text-align: right;
  color: #9a9a9a;

}

.receiver {
  width: auto !important;
  padding: 4px 10px 7px !important;
  border-radius: 10px 10px 10px 0;
  background: #ffffff;
  font-size: 12px;
  text-shadow: 0 1px 1px rgba(0, 0, 0, .2);
  word-wrap: break-word;
  display: inline-block;
}

.sender {
  float: right;
  width: auto !important;
  background: #dcf8c6;
  border-radius: 10px 10px 0 10px;
  padding: 4px 10px 7px !important;
  font-size: 12px;
  text-shadow: 0 1px 1px rgba(0, 0, 0, .2);
  display: inline-block;
  word-wrap: break-word;
}


/*Reply*/

.reply {
  height: 60px;
  width: 100%;
  background-color: #f5f1ee;
  padding: 10px 5px 10px 5px !important;
  margin: 0 !important;
  z-index: 1000;
}

.reply-emojis {
  padding: 5px !important;
}

.reply-emojis i {
  text-align: center;
  padding: 5px 5px 5px 5px !important;
  color: #93918f;
  cursor: pointer;
}

.reply-recording {
  padding: 5px !important;
}

.reply-recording i {
  text-align: center;
  padding: 5px !important;
  color: #93918f;
  cursor: pointer;
}

.reply-send {
  padding: 5px !important;
}

.reply-send i {
  text-align: center;
  padding: 5px !important;
  color: #93918f;
  cursor: pointer;
}

.reply-main {
  padding: 2px 5px !important;
}

.reply-main textarea {
  width: 100%;
  resize: none;
  overflow: hidden;
  padding: 5px !important;
  outline: none;
  border: none;
  text-indent: 5px;
  box-shadow: none;
  height: 100%;
  font-size: 16px;
}

.reply-main textarea:focus {
  outline: none;
  border: none;
  text-indent: 5px;
  box-shadow: none;
}

@media screen and (max-width: 700px) {
  .app {
    top: 0;
    height: 100%;
  }
  .heading {
    height: 70px;
    background-color: #009688;
  }
  .fa-2x {
    font-size: 2.3em !important;
  }
  .heading-avatar {
    padding: 0 !important;
  }
  .heading-avatar-icon img {
    height: 50px;
    width: 50px;
  }
  .heading-compose {
    padding: 5px !important;
  }
  .heading-compose i {
    color: #fff;
    cursor: pointer;
  }
  .heading-dot {
    padding: 5px !important;
    margin-left: 10px !important;
  }
  .heading-dot i {
    color: #fff;
    cursor: pointer;
  }
  .sideBar {
    height: calc(100% - 130px);
  }
  .sideBar-body {
    height: 80px;
  }
  .sideBar-avatar {
    text-align: left;
    padding: 0 8px !important;
  }
  .avatar-icon img {
    height: 55px;
    width: 55px;
  }
  .sideBar-main {
    padding: 0 !important;
  }
  .sideBar-main .row {
    padding: 0 !important;
    margin: 0 !important;
  }
  .sideBar-name {
    padding: 10px 5px !important;
  }
  .name-meta {
    font-size: 16px;
    padding: 5% !important;
  }
  .sideBar-time {
    padding: 10px !important;
  }
  .time-meta {
    text-align: right;
    font-size: 14px;
    padding: 4% !important;
    color: rgba(0, 0, 0, .4);
    vertical-align: baseline;
  }
  .message {
    height: calc(100% - 140px);
  }
  .reply {
    height: 70px;
  }
  .reply-emojis {
    padding: 5px 0 !important;
  }
  .reply-emojis i {
    padding: 5px 2px !important;
    font-size: 1.8em !important;
  }
  .reply-main {
    padding: 2px 8px !important;
  }
  .reply-main textarea {
    padding: 8px !important;
    font-size: 18px;
  }
  .reply-recording {
    padding: 5px 0 !important;
  }
  .reply-recording i {
    padding: 5px 0 !important;
    font-size: 1.8em !important;
  }
  .reply-send {
    padding: 5px 0 !important;
  }
  .reply-send i {
    padding: 5px 2px 5px 0 !important;
    font-size: 1.8em !important;
  }
}
.last-notes {
  position: sticky;
  top: 0;
  color: #fff;
}
.user-info {
  height: 25%;
  background: #ddd;
  color: #000;
  font-weight: 800;
}
.user-info .row {
  height: 100%;
  padding: 10px;
}
.user-info .row .col-6 {
  display: flex;
  justify-content: center;
  align-items: center;
}
.user-info .row .col-6 span{
  height: unset;
}
.dropdown-general {
  height: 100%;
  display: grid;
}
.dropdown-area {
  display: flex;
  align-items: center;
  border-bottom: 1px solid;
  height: 40px;
}
.dropdown-area:hover {
  cursor: pointer;
}
.dropdown-area span {
  height: unset;
  font-size: 20px;
  color: #fff;
  margin: 0 0 0 20px;
  display: flex;
  justify-content: space-between;
  padding-right: 20px;
}
.spec-box {
  position: fixed;
  width: 0;
  opacity: 0;
  height: 0;
  background: #2a2f32;
  bottom: 65px;
  z-index: 99999;
  overflow: hidden;
  border-radius: 10px 10px 0 10px;
}
.open-box {
  animation-name: box-animation-open;
  animation-duration: 2s;
  opacity: 1;
}
.close-box {
  animation-name: box-animation-close;
  animation-duration: 2s;
  opacity: 0;
}
@keyframes box-animation-open {
  0%   {height:0px; width:0px; opacity: 0;}
  25%  {height:0; width:450px; opacity: 0.5;}
  50%  {height:100px; width:450px; opacity: 1;}
}
@keyframes box-animation-close {
  0%   {height:100px; width:450px; opacity: 1;}
  25%  {height:0; width:450px; opacity: 0.5;}
  50%  {height:0; width:0; opacity: 0;}
}
.dropdown-area-spec {
  position: absolute;
  width: 150px;
  height: 0;
  background: #fff;
  right: 10px;
  overflow: hidden;
  top: 10px;
  transition: 1s;
  display: grid;
}
.dropdown-item-spec {
  display: flex;
  align-items: center;
  justify-content: start;
  padding: 0 0 0 10px;
}
.dropdown-item-spec span {
  width: unset;
  height: unset;
}
.advisor-camera {
  position: absolute;
  width: 360px;
  height: 220px;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 10px;
  right: 10px;
  border: 2px solid;
  cursor: move;
}
.p-relative {
  position: relative;
}
.tab {
  float: left;
  border: 1px solid #ccc;
  background-color: #f1f1f1;
  width: 20%;
  height: 450px;
}

/* Style the buttons that are used to open the tab content */
.tab button {
  display: block;
  background-color: inherit;
  color: black;
  padding: 22px 16px;
  width: 100%;
  border: none;
  outline: none;
  text-align: left;
  cursor: pointer;
  transition: 0.3s;
}

/* Change background color of buttons on hover */
.tab button:hover {
  background-color: #ddd;
}

/* Create an active/current "tab button" class */
.tab button.active {
  background-color: #ccc;
}

/* Style the tab content */
.tabcontent {
  float: left;
  padding: 0px 12px;
  border: 1px solid #ccc;
  width: 80%;
  border-left: none;
  height: 450px;
}
.items-head {
  height: 50px;
  display: flex;
  justify-content: start;
  align-items: center;
  padding-left: 10px;
  font-weight: bold;
  background: #0505ba linear-gradient(
    271deg
    , #007bff, transparent);
  color: #fff;
  border-radius: 10px 10px 0px 0px;
}
.items-body {
  border: 1px solid #ddd;
  border-radius: 0 0 10px 10px;
  padding: 0 5px;
  height: 350px;
  overflow-y: scroll;
}
.items-thead {
  position: sticky;
  top: 0;
  background: #ffffff;
  border-bottom: 1px solid #e5e7eb;
}
.statics-area {
  height: 200px;
  
  border-radius: 10px;
  display: grid;
  align-items: center;
  padding-left: 20px;
  font-size: 25px;
  color: #fff;
  font-weight: 800;
  margin-bottom: 20px;
}
.statiscs-row .col-3:first-child .statics-area {
  background: #0505ba linear-gradient(
    271deg
    , #007bff, transparent);
}
.statiscs-row .col-3:nth-child(2) .statics-area {
  background: #f6960b linear-gradient(
    271deg
    , #e7ac58, transparent);
}
.statiscs-row .col-3:nth-child(3) .statics-area {
  background: #2982cc linear-gradient(
    271deg
    , #5090c4, transparent);
}
.statiscs-row .col-3:nth-child(4) .statics-area {
  background: #d93737 linear-gradient(
    271deg
    , #cf6b6b, transparent);
}
.f-60 {
  font-size: 60px;
}
h2.f-60 {
  position: relative;
  display: flex;
  align-items: center;
}
h2.f-60::after {
  content: "";
  position: absolute;
  height: 2px;
  width: 60%;
  background: #fff;
  right: 10px;
  animation: h3-open;
  transition: 1s;
  animation-duration: 2s;
}
h2.f-60::before {
  content: "";
  position: absolute;
  height: 2px;
  width: 40%;
  background: #fff;
  right: 10px;
  margin-top: 10px;
  animation: h3-open;
  transition: 1s;
  animation-duration: 2s;
}

@keyframes h3-open {
  0%   {right: -100px;}
  25%  {right: 10px;}
}
.h-16 {
  height: 3rem!important;
}
th {
  cursor: pointer;
}
.spec-text-area {
  background: #0f0f0f;
  color: #fff;
  border: unset;
}
.spec-col {
  border-bottom: 1px solid #1f1e1e;
  padding-bottom: 5px;
}
.room-url-input {
  width: 100%;
  background: #0f0f0f;
  border: unset;
  border-radius: 10px;
  color: #fff;
}
.room-url-input:focus {
  outline: unset;
  box-shadow: unset;
}
.spec-col-6 {
  display: grid;
  justify-content: start;
  align-items: center;
}
.f-14 {
  font-size: 14px!important;
}
.menu-items.active {
  background: #56535357;
}
.save-button {
  position: absolute;
  bottom: 10px;
  right: -100px;
  transition: 1s;
}
.general-text-area {
  background: #0f0f0f;
  color: #fff;
  border: unset;
}
.toastr {
  width: 300px;
    height: 60px;
    position: fixed;
    background: linear-gradient(
45deg
, #467443, #27ff02);
    right: 10px;
    bottom: -100px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    font-weight: 800;
    color: #fff;
    border-radius: 20px;
    transition: .5s;
}
.toastr span {
  height: unset;
  width: unset;
}
.h-unset {
  height: unset;
}
.w-unset {
  width: unset;
}
.hovered-effect:hover {
  background: #272626;
  cursor: pointer;
}
a:hover {
  text-decoration: none;
}
video {
  transform: rotateY(
180deg
);
}
.record-span {
  position: absolute;
  left: 10px;
  margin-top: 5px;
  color: #fff;
  padding-left: 25px;
}
.record-span::after {
  content: "";
  position: absolute;
  width: 20px;
  height: 20px;
  background: #b00;
  left: 0px;
  border-radius: 50%;
}